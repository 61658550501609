<template>
    <div class="background-container">
    <!-- 背景图片 -->
    <img class="background-image" src="../assets/home2.png" alt="Background Image">
  <div class="login-form">
    <form @submit.prevent="login" class="form-container">
      <label for="username">用户名/Username:</label>
      <input type="text" v-model="username" required />

      <label for="password">密码/Password:</label>
      <input type="password" v-model="password" required />

      <button type="submit" class="login-button">登录Login</button>
    </form>

    <p v-if="error" class="error-message">{{ error }}</p>
    <p class="registered-link">
      没有注册？<router-link to="/register">请注册/register</router-link>
    </p>
    <p class="registered-link2">
      <router-link to="/">回到主页面/homepage</router-link>
    </p>
  </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: null,
    };
  },
  methods: {
    login() {
      // Reset error message
      this.error = null;

      // Make a POST request to your Django login API
      axios.post('http://8.130.51.49:8088/login/', {
        username: this.username,
        password: this.password,
      })
      .then(response => {
        if (response.data.success) {
          // Redirect to the dashboard or another page upon successful login
          // this.$visited=true;
          // this.$userbiao=this.username;
          // this.$passwordbiao=this.password;

          this.$globalData.visited = true;
          this.$globalData.userbiao = this.username;
          this.$globalData.passwordbiao = this.password;

          // Save the values to localStorage
          localStorage.setItem('visited', 'true');
          localStorage.setItem('userbiao', this.username);
          localStorage.setItem('passwordbiao', this.password);

          console.log('Login successful. Setting global variables.');
          console.log('Current username:', this.$globalData.userbiao);
          this.$router.push('/');
        } else {
          this.error = response.data.message || 'Login failed.';
        }
      })
      .catch(error => {
        console.error('Error logging in:', error);
        this.error = 'Login failed. Please try again later.';
      });
    }
  }
}
</script>

<style scoped>
.background-container {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
}

/* 背景图片样式 */
.background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: fill;
}

.login-form {
  top:25%;
  max-width: 400px;
  margin: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button {
  background-color: #008CBA;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #005A7F;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.registered-link {
  margin-top: 10px;
  text-align: center;
}

.registered-link a {
  color: #008CBA;
  text-decoration: none;
  font-weight: bold;
}

.registered-link a:hover {
  text-decoration: underline;
}

.registered-link2 {
  margin-top: 10px;
  text-align: center;
}

.registered-link2 a {
  color: lightseagreen;
  text-decoration: none;
  font-weight: bold;
}

.registered-link2 a:hover {
  text-decoration: underline;
}
</style>