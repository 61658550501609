var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"word-like-container"},[_c('h1',{staticClass:"title"},[_vm._v("帮助文档 / Document")]),_c('router-link',{staticClass:"home-link",attrs:{"to":"/"}},[_vm._v("返回 / back")]),_c('div',{staticClass:"login-register-links"},[_c('router-link',{staticClass:"login-link",attrs:{"to":"/help"}},[_vm._v("汉语文档")]),_c('router-link',{staticClass:"register-link",attrs:{"to":"/document"}},[_vm._v("English Document")])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" The image represents a section of the homepage; clicking on 【登录/注册】 will lead you to the 【登录/注册】 (Login/Register) page. Registration and login are required to access the functionalities.Clicking on the rotating images labeled 【衍散射】 (Diffraction), 【吸收谱】 (Absorption Spectrum), and 【图像】 (Image) will take you to the respective pages. Some functionalities are still under development. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/home.png"),"alt":"Image 1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" Click on 【登录/login】 to access the login page, where you can enter the corresponding username and password. If you haven't registered yet, click on \"注册\" (Register) to sign up. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/2.png"),"alt":"Image 2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" Click on 【注册/register】 to proceed with the registration. If already registered, click on 【登录】 (Login). ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/3.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" Clicking on 【用户】 allows you to modify personal information. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/4.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" The diffraction consists of four parts, from left to right, and from top to bottom, respectively: High-Resolution Image Reconstruction: Reconstruction of the image in high resolution. Initialization Parameters: Configuration of parameters by converting the image into curves, including the initial settings for the image. Image Integration Curve: Curve obtained by transforming the high-resolution image, used for analysis and processing. Prediction of Molecular Contour Parameters based on Integration Curve: Utilizing the integration curve for predicting parameters related to the molecular contour. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/5.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" To reconstruct a high-resolution image, first click on 【上传图像】 (Upload Image), choose a .tif file for upload; click on 【重建】 (Reconstruct), and the uploaded .tif file will be displayed. Subsequently, the image reconstructed by the deep learning model will be showcased in the right-hand box. Click on 【保存png】 (Save as PNG) or 【保存tif】 (Save as TIFF) to obtain image files, and click on 【保存dat】 (Save as DAT) to convert the image into a .dat file for the integration curve and save it. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/6.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" Click on 【加载配置】(load cfg) to display the calculation parameters for converting the image into an integration curve, allowing for modifications. Click on 【保存配置】(save cfg) to save the parameters. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/7.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" Click on 【加载dat】(load dat) to upload and display your own .dat file. Click on 【积分】(Integrate) to display the integration curve of our super-resolution reconstructed image. Click on 【相减】(Subtract) to choose two integration curves for subtraction, obtaining the subtracted curve. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/8.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" Click on 【预测】(predict) to predict molecular contour parameters from the subtracted curve. ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/9.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/10.png"),"alt":"Image 3"}})])
}]

export { render, staticRenderFns }