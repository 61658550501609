<template>
  <div class="background-container">
    <!-- 背景图片 -->
    <img class="background-image" src="../assets/home2.png" alt="Background Image">

    <!-- 表单容器 -->
    <div class="form-container">
      <form @submit.prevent="saveProfile">
        <label for="username">用户名/Username:</label>
        <input type="text" v-model="username" required />

        <label for="password">密码/Password:</label>
        <input type="password" v-model="password" required />

        <label for="gender">性别/Gender:</label>
        <input type="text" v-model="gender" />

        <label for="age">年龄/Age:</label>
        <input type="number" v-model="age" />

        <label for="occupation">职业/Occupation:</label>
        <input type="text" v-model="occupation" />

        <label for="city">城市/City:</label>
        <input type="text" v-model="city" />

        <button type="button" @click="saveProfile" class="save-button" :disabled="updating">
          保存/Save
        </button>
      </form>

      <p v-if="error" class="error-message">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: this.$globalData.userbiao,
      password: this.$globalData.passwordbiao,
      gender: '',
      age: null,
      occupation: '',
      city: '',
      updating: false,
      error: null,
    };
  },
  mounted() {
    // Fetch user list from backend when component is mounted
    this.fetchUserProfile();
  },
  methods: {

    fetchUserProfile() {
      // Fetch user information for the current user from backend
      axios.post(`http://8.130.51.49:8088/get_user/${this.$globalData.userbiao}/`)
        .then(response => {
          const user = response.data.user;
          if (user) {
            this.password=user.password;
            this.gender = user.gender;
            this.age = user.age;
            this.occupation = user.occupation;
            this.city = user.city;
          }
        })
        .catch(error => {
          console.error('Error fetching user profile:', error);
        });
    },
    saveProfile() {
      // Save the updated profile
      this.updating = true;
      this.error = null;

      // Make a PUT request to update user profile
      axios.post(`http://8.130.51.49:8088/update_user/${this.$globalData.userbiao}/`, {
        username:this.username,
        password: this.password,
        gender: this.gender,
        age: this.age,
        occupation: this.occupation,
        city: this.city,
      })
      .then(response => {
        this.updating = false;
        if (response.data.success) {
          // Update successful, fetch updated user list
          this.fetchUserProfile();
          window.alert('用户信息更新成功/User information updated successfully!');
        } else {
          this.error = response.data.message || '用户信息更新失败/User information update failed.';
        }
      })
      .catch(error => {
        this.updating = false;
        console.error('Error updating user profile:', error);
        this.error = '用户信息更新失败/User information update failed.';
      });
    },
  },
};
</script>

<style scoped>
/* 背景容器样式 */
.background-container {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
}

/* 背景图片样式 */
.background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: fill;
}

/* 表单容器样式 */
/*.form-container {*/
/*  max-width: 400px;*/
/*  margin: auto;*/
/*  position: relative;*/
/*  z-index: 2;*/
/*   flex-direction: column;*/
/*}*/

/*label {*/
/*  margin-bottom: 8px;*/
/*  font-weight: bold;*/
/*}*/

/*input {*/
/*  padding: 8px;*/
/*  margin-bottom: 16px;*/
/*  border: 1px solid #ccc;*/
/*  border-radius: 4px;*/
/*}*/
.form-container {
  top:13%;
  max-width: 400px;
  margin: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
  width: 100%; /* 使标签宽度适应容器宽度 */
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* 使输入框宽度适应容器宽度 */
}

.save-button {
  background-color: palevioletred;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:100%;
}

.save-button:disabled {
  background-color: #CCCCCC;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* 其他样式省略 */
</style>
