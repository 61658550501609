<template>
  <div class="full-container">
    <div class="navbar">
      <div class="brand">
        <span class="brand-name">IPS Brain</span>
        <router-link to="/he63" tag="button" @click.native="switchLanguage('chinese')" class="chinese-link">语言：中文/</router-link>

      <!-- English language link -->
        <router-link to="/he62" tag="button" @click.native="switchLanguage('english')" class="english-link">English</router-link>
      </div>
      <div class="menu">
        <div class="menu-item1" @mouseover="highlightMenuItem(0)" @mouseleave="resetMenuItem()">
          <router-link to="/" CLASS="custom-link">
          <span :class="{ 'highlighted': highlightedIndex === 0 }">Home</span>
            </router-link>
        </div>
        <div class="menu-item" @mouseover="highlightMenuItem(1)" @mouseleave="resetMenuItem()">
          <span :class="{ 'highlighted': highlightedIndex === 1 }">Diffraction</span>
        </div>
        <div class="menu-item" @mouseover="highlightMenuItem(2)" @mouseleave="resetMenuItem()">
          <span :class="{ 'highlighted': highlightedIndex === 2 }">Absorption</span>
        </div>
        <div class="menu-item" @mouseover="highlightMenuItem(3)" @mouseleave="resetMenuItem()">
          <span :class="{ 'highlighted': highlightedIndex === 3 }">Image</span>
        </div>
        <div class="menu-item" @mouseover="highlightMenuItem(4)" @mouseleave="resetMenuItem()">
          <router-link to="/user" CLASS="custom-link">
          <span :class="{ 'highlighted': highlightedIndex === 4 }">User</span>
          </router-link>
        </div>
      </div>
    </div>


    <!-- New content section -->
    <div class="content-container">


      <!-- Upper Content -->
      <div class="upper-content">
        <div class="left-box">
          <div class="left">
<!--             Replace the placeholder with your content -->
            <p class="p1">High-resolution image reconstruction </p>
            <div>
              <input v-model="img1name" :style="{ border: 'none' }" id="img1name" type="text" class="form-input7">
            </div>
            <div class="image-container">
              <img v-if="imageUrl" :src="imageUrl" alt="Uploaded Image">
            </div>
            <label for="file-input" class="upload-button">
              Upload Image
              <input type="file" id="file-input" @change="handleFileChange">
            </label>
            <button class="reconstruct" @click="showAndReconstructImage">Reconstruct</button>
            <!--          <button class="preview" >Preview</button>-->
          </div>
          <div class="right">
            <input v-model="img2name" :style="{ border: 'none' }" id="img2name" type="text" class="form-input8">
            <div class="image-container2">
              <img v-if="imageUrl2" :src="imageUrl2" alt="Uploaded Image">
            </div>
            <div>
<!--              <button class="reconstruct" @click="showAndReconstructImage">Reconstruct</button>-->
              <button class="save" @click="saveImage">Save_png</button>
              <button class="save2" @click="downloadTif">Save_tif</button>
              <button class="lower-button4" size="small"  @click="downloadDat">Save_dat</button>
            </div>
          </div>
        </div>
        <div class="right-box">
          <!-- Replace the placeholder with your content -->
          <p> <button class="centered-button">Initial Parameter to Integrate Tensity</button></p>
          <div>
            <form @submit.prevent="submitForm" class="three-column-form">
              <div class="column1">
              <label for="detector" class="form-label">Detector:</label>
               <br>
              <input v-model="detector" id="detector" type="text" class="form-input">
                <br>
              <label for="centerPointX" class="form-label">X Center:</label>
                <br>
              <input v-model="centerPointX" id="centerPointX" type="text" class="form-input">
                <br>
              <label for="centerPointY" class="form-label">Y Center:</label>
                <br>
              <input v-model="centerPointY" id="centerPointY" type="text" class="form-input">
                <br>
              <label for="wavelength" class="form-label">Wavelength:</label>
                <br>
              <input v-model="wavelength" id="wavelength" type="text" class="form-input">
                <span class="unit">[A]</span>
              </div >
              <div class="column2">
              <label for="sampleDistance" class="form-label">Sample_Detector Distance:</label>
                <br>
              <input v-model="sampleDistance" id="sampleDistance" type="text" class="form-input6">
                <span class="unit">[mm]</span>
                    <br>
              <label for="pixelSizeX" class="form-label">Detector Pixel Size:</label>
                <br>
                <span class="unit">&nbsp;&nbsp;&nbsp;&nbsp;x:&nbsp;</span>
              <input v-model="pixelSizeX" id="pixelSizeX" type="text" class="form-input6">
                <span class="unit">[μm]</span><br>
              <label for="tilt" class="form-label">Detector Angles:</label><br>
                <span class="unit">&nbsp;&nbsp;Tilt:&nbsp;</span>
              <input v-model="tilt" id="tilt" type="text" class="form-input6">
                <span class="unit">[μm]</span>
              </div>
              <div class="column3">
              <label for="pixelSizeY" class="form-label"></label><br>
                <span class="unit">y:&nbsp;</span>
              <input v-model="pixelSizeY" id="pixelSizeY" type="text" class="form-input2">
                <span class="unit">[μm]</span><br>
                <label for="tiltPlanRot" class="form-label"></label><br>
                <span class="unit">Tilt Pane Rot:</span>
              <input v-model="tiltPlanRot" id="tiltPlanRot" type="text" class="form-input22">
              </div>
              <button class="form2-button" @click="loadcfg">Load_cfg</button>
              <button type="submit" class="form-button">Save_cfg</button>
            </form>
<!--            <form @submit.prevent="submitForm" class="three-column-form">-->
<!--              <label for="detector" class="form-label">Detector:</label>-->
<!--              <input v-model="detector" id="detector" type="text" class="form-input">-->
<!--              <label for="sampleDistance" class="form-label">Sample Detector Distance[mm]:</label>-->
<!--              <input v-model="sampleDistance" id="sampleDistance" type="text" class="form-input">-->
<!--              <br>-->
<!--              <label for="centerPointX" class="form-label">X center:</label>-->
<!--              <input v-model="centerPointX" id="centerPointX" type="text" class="form-input">-->
<!--              <label for="pixelSizeX" class="form-label">Detector Pixel Size X[μm]:</label>-->
<!--              <input v-model="pixelSizeX" id="pixelSizeX" type="text" class="form-input">-->
<!--              <label for="pixelSizeY" class="form-label">Detector Pixel Size Y[μm]:</label>-->
<!--              <input v-model="pixelSizeY" id="pixelSizeY" type="text" class="form-input">-->
<!--              <br>-->
<!--              <label for="centerPointY" class="form-label">Y center:</label>-->
<!--              <input v-model="centerPointY" id="centerPointY" type="text" class="form-input">-->
<!--              <label for="tilt" class="form-label">Detector Angels Tilt:[μm]</label>-->
<!--              <input v-model="tilt" id="tilt" type="text" class="form-input">-->
<!--              <label for="tiltPlanRot" class="form-label">Detector Tilt Plan Rot:</label>-->
<!--              <input v-model="tiltPlanRot" id="tiltPlanRot" type="text" class="form-input">-->
<!--              <br>-->
<!--              <label for="wavelength" class="form-label">Wavelength[A]:</label>-->
<!--              <input v-model="wavelength" id="wavelength" type="text" class="form-input">-->
<!--              <br>-->
<!--              <button class="form2-button" @click="loadcfg">Load cfg</button>-->
<!--              <button type="submit" class="form-button">Save cfg</button>-->
<!--            </form>-->
          </div>
        </div>
      </div>
      <div class="content-divider"></div>
      <!-- Lower Content -->
      <div class="lower-content">
        <!-- Content for the lower part if needed -->
        <div class="left-box">
          <!-- Replace the placeholder with your content -->
          <!-- <p>Left Box Content</p> -->
          <div class="plot-panel">
            <p class="p1">Plot Panel</p>
            <div class="curve">
              <div class="left-curve">
                <div>
                  <el-card class="curve-card">
                    <div id="multiChart" style="width: 300px; height: 180px;"></div>
                  </el-card>
                </div>
                <div>
                  <el-upload class="upload-demo" action="http://8.130.51.49:8088/api/diffraction/uploadfiles/"
                    :show-file-list="false" :on-success="handleSuccess" :on-preview="handlePreview"
                    :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="8"
                    :on-exceed="handleExceed" :file-list="fileList">
                    <el-button class="lower-button1" size="small" type="primary">Load_dat</el-button>
<!--                    <button class="lower-button4" size="small" type="primary" @click="downloadDat">Save_dat</button>-->
                    <el-button slot="tip" class="lower-button2" size="small" type="primary" @click="showTiffCurve">
                      Integrate</el-button>
                    <!-- <div slot="tip" class="el-upload__tip">
                      只能上传dat文件，且不超过500kb
                    </div> -->
                  </el-upload>

                </div>
              </div>
              <div class="right-curve">
                <div>
                  <el-card class="curve-card">
                    <div id="subtractChart" style="width: 300px; height: 180px;"></div>
                  </el-card>
                </div>
                <el-popover placement="right" width="350px;" trigger="click">
                  <el-form label-position="right" label-width="100px">
                    <el-form-item label="被减数曲线">
                      <el-select v-model="valueSubtracted" placeholder="被减数曲线">
                        <el-option v-for="(file, index) in chartFilenameList" :key="index" :value="index" :label="file">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="减数曲线">
                      <el-select v-model="valueSubtract" placeholder="减数曲线">
                        <el-option v-for="(file, index) in chartFilenameList" :key="index" :value="index" :label="file">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="subtract">确定</el-button>
                    </el-form-item>
                  </el-form>
                  <el-button class="lower-button3" slot="reference">Subtract</el-button>
                </el-popover>
              </div>
            </div>
          </div>


        </div>
        <div class="right-box">
          <!-- Replace the placeholder with your content -->
          <p> <button class="centered-button">Shape Parameter</button></p>
          <div>
            <form class="three-column-form1">
              <div class="form-item">
                <label for="mw" class="form-label99">Molecular Weight:</label>
                <div><input v-model="mw" id="mw" type="text" class="form-input99">
                <span class="unit">[kDa]</span></div>
              </div>
              <div class="form-item">
                <label for="dmax" class="form-label99">Envelope Diameter:</label>
                <div><input v-model="dmax" id="dmax" type="text" class="form-input99">
                <span class="unit">[nm]</span></div>
              </div>
              <!-- <br> -->

              <!-- <br> -->
              <div class="form-item">
                <label for="Rg" class="form-label99">Rg:</label>
                <div><input v-model="Rg" id="Rg" type="text" class="form-input99">
                <span class="unit">[nm]</span></div>
              </div>
              <!-- <br> -->
              <el-button class="predict-button" type="primary" @click="predict" style="margin-top:260px">Predict</el-button>
            </form>
          </div>
        </div>

        <!-- Upper Content -->
      </div>
    </div>
  </div>
</template>

<script>
  // import axios from "axios";

  import axios from "axios";

  import * as echarts from "echarts";

  export default {
    data() {
      return {
        //Lower content params
        subtractChartOptions: {},
        subtractData: "",
        valueSubtracted: "",
        valueSubtract: "",
        chartFilenameList: [],
        subtractChart: null, //subtract
        multioptions: {},
        multichart: null,
        chartDataList: [],
        fileList: [],
        highlightedIndex: -1,
        mw: "",
        dmax: "",
        Rg: "",

        // Upper content params
        imageUrl: null, // 新的图片URL1
        imageUrl2: null, // 新的图片URL2
        selectedFile: null, // 新的文件对象1// 新的文件对象2
        detector: '',
        centerPointX: null,
        centerPointY: null,
        wavelength: '',
        sampleDistance: null,
        pixelSizeX: null,
        pixelSizeY: null,
        tilt: null,
        tiltPlanRot: null,
        img1name:null,
        img2name:null,
      };
    },
    mounted() {
      this.initMultiChart();
      this.initsubtractChart();
    },
    methods: {
      downloadDat() {
      if (this.selectedFile) {
        // Make a POST request to the backend with the selected file name
        const formData3 = new FormData();
        formData3.append("globalVariable", this.img1name.slice(0, -4));
        axios.post('http://8.130.51.49:8088/download_dat/', formData3, { responseType: 'arraybuffer' })
          .then(response => {
            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'application/octet-stream' });

            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.img1name+'_build.dat';
            link.click();
          })
          .catch(error => {
            console.error('Error downloading DAT:', error);
          });
      }
    },
      downloadTif() {
      // Make a POST request to the backend
        const formData3 = new FormData();
        formData3.append("globalVariable", this.img1name.slice(0, -4));
        axios.post('http://8.130.51.49:8088/download_tif/', formData3, { responseType: 'arraybuffer' })
        .then(response => {
          // Create a blob from the response data
          const blob = new Blob([response.data], { type: 'image/tiff' });

          // Create a link element and trigger a download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.img1name+'_build.tif';
          link.click();
        })
        .catch(error => {
          console.error('Error downloading TIF:', error);
        });
    },
      //Lower content methods
      showTiffCurve() {
        const formData3 = new FormData();
        formData3.append("globalVariable", this.img1name.slice(0, -4));
        axios.post("http://8.130.51.49:8088/api/diffraction/showTiffCurve/", formData3)
          .then(response => {
            this.chartDataList = this.chartDataList.concat(response.data.data_dict_list)
            this.chartFilenameList = this.chartFilenameList.concat(response.data.filenameList)
            this.updataMultiChartOptions()
          })
      },
      updateSubtractChartOptions() {
        this.subtractChartOptions = {
          animation: false,
          // title: {
          //   text: "相减后的积分曲线",
          //   // textAlign:'center',
          //   top: "bottom",
          // },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            top: 28,
            left: 28,
            right: 25,
            bottom: 20,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            name: "Q",
            minorTick: {
              show: true,
            },
            minorSplitLine: {
              show: true,
            },
          },
          yAxis: {
            name: "I",
            min: -5,
            max: 5,
            minorTick: {
              show: true,
            },
            minorSplitLine: {
              show: true,
            },
          },
          dataZoom: [{
              show: true,
              type: "inside",
              filterMode: "none",
              xAxisIndex: [0],
              startValue: -0.25,
              endValue: 0.5,
            },
            {
              show: true,
              type: "inside",
              filterMode: "none",
              yAxisIndex: [0],
              startValue: -0.5,
              endValue: 0.8,
            },
          ],
          series: [{
            type: "line",
            showSymbol: false,
            clip: true,
            data: this.subtractData,
          }, ],
        };
        console.log("subtractChartOptions", this.subtractChartOptions);
        this.subtractChart.setOption(this.subtractChartOptions, true);
      },
      initsubtractChart() {
        this.subtractChart = echarts.init(
          document.getElementById("subtractChart")
        );
        this.updateSubtractChartOptions();
      },
      subtract() {
        console.log(this.valueSubtract);
        console.log(this.valueSubtracted);
        var formData = {
          subtracted: this.chartFilenameList[this.valueSubtracted],
          subtract: this.chartFilenameList[this.valueSubtract],
        };
        console.log('formdata', formData)
        axios
          .post("http://8.130.51.49:8088/api/diffraction/subtract/", formData)
          .then((response) => {
            console.log(response)
            this.subtractData = response.data.data_dict_list;
            this.updateSubtractChartOptions()
          })
          .catch((error) => {
            console.log(error);
          });
      },
      initMultiChart() {
        this.multichart = echarts.init(document.getElementById("multiChart"));
        this.updataMultiChartOptions();
      },
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
        );
      },
      handlePreview(file) {
        console.log("handlepreview", file);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      handleRemove(file, fileList) {
        // console.log("remove",file)
        let index = this.chartFilenameList.indexOf(file.name);
        this.chartDataList.splice(index, 1);
        this.chartFilenameList.splice(index, 1);
        this.updataMultiChartOptions();
        console.log("chartdata", this.chartDataList);
        console.log("chartfilename", this.chartFilenameList);
      },
      updataMultiChartOptions() {
        var series = [];
        for (var i = 0; i < this.chartDataList.length; i++) {
          var data = {
            name: this.chartFilenameList[i],
            type: "line",
            showSymbol: false,
            clip: true,
            data: this.chartDataList[i],
          };
          series.push(data);
        }
        // console.log("series",series)
        this.multioptions = {
          animation: false,
          // title: {
          //   text: "积分曲线",
          //   // textAlign:'center',
          //   top: "bottom",
          // },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: this.chartFilenameList,
          },
          grid: {
            top: 28,
            left: 28,
            right: 25,
            bottom: 20,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            name: "Q",
            minorTick: {
              show: true,
            },
            minorSplitLine: {
              show: true,
            },
          },
          yAxis: {
            name: "I",
            min: -5,
            max: 5,
            minorTick: {
              show: true,
            },
            minorSplitLine: {
              show: true,
            },
          },
          dataZoom: [{
              show: true,
              type: "inside",
              filterMode: "none",
              xAxisIndex: [0],
              startValue: -0.25,
              endValue: 0.5,
            },
            {
              show: true,
              type: "inside",
              filterMode: "none",
              yAxisIndex: [0],
              startValue: -0.5,
              endValue: 1.5,
            },
          ],
          series: series,
        };
        console.log("multioptions", this.multioptions);
        this.multichart.setOption(this.multioptions, true);
      },
      handleSuccess(response, file, fileList) {
        if (file.response.message == "成功") {
          this.chartDataList.push(file.response.data_dict_list);
          this.chartFilenameList.push(file.name);
          this.updataMultiChartOptions();
          // console.log("file", file)
          // console.log("fileList", fileList)
        }
      },
      highlightMenuItem(index) {
        this.highlightedIndex = index;
      },
      resetMenuItem() {
        this.highlightedIndex = -1;
      },
      predict() {
        var formData = {
          subtracted: this.chartFilenameList[this.valueSubtracted],
          subtract: this.chartFilenameList[this.valueSubtract],
        };
        axios.post("http://8.130.51.49:8088/api/diffraction/predict/", formData)
          .then(response => {
            var result = response.data
            var mwText = result.mw;
            var mwFloat = parseFloat(mwText);
            var mwFloat2=(mwFloat / 1000).toFixed(3);
            this.mw = mwFloat2.toString() ;
            this.dmax = result.dmax ;
            this.Rg = result.Rg ;
          })
      },


      handleFileChange(event) {
        this.selectedFile = event.target.files[0];
        this.imageUrl = URL.createObjectURL(this.selectedFile);
        if (this.selectedFile) {
        this.img1name = this.selectedFile.name;
      }
      },
      async showAndReconstructImage() {
        const formData = new FormData();
        formData.append("image", this.selectedFile);
          formData.append("globalVariable", this.img1name.slice(0, -4) );
        try {
          // Send the image to the "show_image" endpoint
          const response1 = await axios.post("http://8.130.51.49:8088/show/", formData, {
            responseType: 'blob', // Set responseType to 'blob'
            headers: {
              'Content-Type': 'multipart/form-data', // 设置请求头 Content-Type
            },
          });

          // Create a blob URL for the image
          const blob1 = new Blob([response1.data]);
          this.imageUrl = URL.createObjectURL(blob1);
        } catch (error) {
          console.error("Error in showAndReconstructImage (show_image):", error);
        }
        const formData2 = new FormData();
        formData2.append("image", this.selectedFile);
        formData2.append("globalVariable", this.img1name.slice(0, -4) );
        try {
          // const globalVariableValue = this.$myGlobalVariable; // Replace with the actual value
          // Send the image to the "predict_image" endpoint
          const response2 = await axios.post("http://8.130.51.49:8088/predict/", formData2, {
            responseType: 'blob', // Set responseType to 'blob'
            headers: {
              'Content-Type': 'multipart/form-data', // 设置请求头 Content-Type
            },
          });
          // Create a blob URL for the second image
          const blob2 = new Blob([response2.data]);
          this.imageUrl2 = URL.createObjectURL(blob2);
          this.img2name = this.img1name.slice(0, -4) + '_build';
        } catch (error) {
          console.error("Error in showAndReconstructImage (predict_image):", error);
        }
      },
      saveImage() {
        // Assuming imageUrl2 contains the URL of the image you want to save
        if (this.imageUrl2) {
          // Create a link element
          const link = document.createElement('a');

          // Set the href attribute to the image URL
          link.href = this.imageUrl2;

          // Set the download attribute with a desired file name (you can customize it)
          // link.download = 'saved_image.png';
          link.download = this.img2name+'.png';
          // Append the link to the document
          document.body.appendChild(link);

          // Trigger a click on the link to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        } else {
          // Handle the case when there is no image to save
          console.error('No image to save');
        }
      },
      submitForm() {
        const formData = {
          detector: this.detector,
          center_point: [this.centerPointX, this.centerPointY],
          wavelength: this.wavelength,
          sampleDistance: this.sampleDistance,
          detectorPixelSizeX: this.pixelSizeX,
          detectorPixelSizeY: this.pixelSizeY,
          detectorTilt: this.tilt,
          detectorTiltPlanRot: this.tiltPlanRot,
        };
        formData
        axios
          .post("http://8.130.51.49:8088/chuli/", formData)
          .then((response) => {
            console.log('Backend response:', response.data);
          })
          .catch((error) => {
            console.error('Error submitting to backend:', error);
          });
      },
      loadcfg() {
        this.detector = 'pilatus_2m',
          this.centerPointX = '739.045',
          this.centerPointY = '929.809',
          this.wavelength = '1.033',
          this.sampleDistance = '2721.170',
          this.pixelSizeX = '172.0',
          this.pixelSizeY = '172.0',
          this.tilt = '0.256',
          this.tiltPlanRot = '132.577'
      },
    },
  };
</script>

<style scoped>
.custom-link {
  text-decoration: none;
  color: inherit; /* 继承父级元素的颜色 */
}

.chinese-link {
  font-family: '楷体', cursive;
  border: none;
  text-align: center;
  font-size:22px;
  padding: 10px;
  margin-top: 2px;
  margin-left: 24px;
}

.chinese-link2 {
  font-family: '楷体', cursive;
  border: none;
  text-align: center;
  font-size:22px;
  padding: 10px;
  margin-top: 2px;
 margin-left: 24px;
    cursor: pointer;
}

.english-link {
  font-family: 'Times New Roman', serif;
  font-size:22px;
  border: none;
  text-align: center;
  padding: 0px;
  margin-top: 2px;
  margin-left: 0px;
}

  .column1 {
    display: inline-block;
    left:50%;
    margin-right: 0px; /* 调整此值以控制列之间的间距 */
  }
  .column2 {
    display: inline-block;
    left:65%;
    margin-right: 0px; /* 调整此值以控制列之间的间距 */
     vertical-align: top;
  }
  .column3 {
    margin-left: 0px;
    display: inline-block;
    left:70%;
    /* 调整此值以控制列之间的间距 */
    vertical-align: top;
  }
  .full-container {
    display: flex;
    flex-direction: column;
    height: 97vh;
    background-color: #f2f2f2;
    border: 1.5px solid #000;
    overflow: auto;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
  }

  .brand-name {
    font-family: 'Brush Script MT', cursive;
    font-size: 60px;
    -webkit-text-fill-color: transparent;
    /* 字体颜色透明 */
    background-image: linear-gradient(to right, #007bff, #00bfff);
    /* 渐变蓝色边缘 */
    -webkit-background-clip: text;
    /* 用于让文字仍然可见 */
  }

  .menu {
    display: flex;
  }

  .menu-item {
    margin: 0 20px;
    cursor: pointer;
    transition: all 0.3s;
    font-family: '微软雅黑';
    font-size: 30px;
    color: rgb(100, 149, 237);
    padding: 8px 16px;
    /* 添加内边距以增加目标区域 */
    border: 2px solid transparent;
    /* 初始时边框透明 */
  }

  .menu-item .highlighted {
    color: rgb(100, 149, 237);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: text;
  }

  .menu-item:hover {
    transform: scale(1.0);
    border: 2px solid transparent;
    /* 重置边框透明度 */
    border-image: linear-gradient(to right, #4caf50, #00ff00);
    /* 渐变绿色边框 */
    border-image-slice: 1;
    /* 定义边框宽度 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    /* 添加立体的黑色投影 */
  }

  .menu-item1 {
    margin: 0 20px;
    cursor: pointer;
    transition: all 0.3s;
    font-family: '微软雅黑';
    font-size: 30px;
    color: #5bc0de;
    padding: 8px 16px;
    /* 添加内边距以增加目标区域 */
    border: 2px solid transparent;
    /* 初始时边框透明 */
  }

  .menu-item1 .highlighted {
    color: #5bc0de;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: text;
  }

  .menu-item1:hover {
    transform: scale(1.0);
    border: 2px solid transparent;
    /* 重置边框透明度 */
    border-image: linear-gradient(to right, #4caf50, #00ff00);
    /* 渐变绿色边框 */
    border-image-slice: 1;
    /* 定义边框宽度 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    /* 添加立体的黑色投影 */
  }

  .content-container {
    display: flex;
    flex-direction: column;
    height: 150%;
  }

  .content-divider {
    height: 1.5px;
    background-color: #000;
    /* Black color for the divider */
    margin: 8px 0;
    /* Adjust the margin as needed */
  }

  .upper-content {
    display: flex;
    flex: 1;
    height:400px;
  }

  .left-box,
  .right-box {
    display: flex;
    flex: 1;
    padding: 5px;
  }

  .left-box {
    background-color: #f0f0f0;
    /* Replace with your desired background color */
    margin-right: 18px;
    margin-left: 18px;
    border: 1px solid #000;
  }

  .left,
  .right {
    flex: 1;
  }

  .image-container {
    margin-top: 10px;
    margin-left: 95px;
  }

  #file-input {
    display: none;
  }

  .upload-button {
    margin-top: 2px;
    width: 110px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    /*font-weight: bold;*/
    position: absolute;
    left: 8%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
  }

  .preview {
    margin-top: 13px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    position: absolute;
    left: 17%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 保持图片比例并填充容器 */
  }

  .image-container2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 保持图片比例并填充容器 */
  }

  .p1 {
    font-family: 'Times New Roman', sans-serif;
    font-size: 19px;
    margin-bottom: 1px;
  }

  .image-container {
    width: 225px;
    height: 225px;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
    left: 0%;
  }

  .right-box {
    background-color: #f0f0f0;
    /* Replace with your desired background color */
    margin-right: 18px;
    margin-left: 18px;
    border: 1px solid #ccc;
  }

  .reconstruct {
    margin-top: 3px;
    width: 110px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    position: absolute;
    left: 16%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
  }

  .save {
    margin-top: 3px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    position: absolute;
    left: 28%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
  }

    .save2 {
    margin-top: 3px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    position: absolute;
    left: 34%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
  }

  .image-container2 {
    margin-top: 9px;
    width: 225px;
    height: 225px;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
    margin-left: 74px;
  }

  .centered-button {
    margin-left: 200px;
    width: 400px;
    height: 40px;
    border: 2px solid gray;
    background-color: transparent;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* 添加黑色投影 */
    line-height: 40px;
    /* 文本垂直居中 */
    text-align: center;
    /* 文本水平居中 */
    font-size: 20px;
    font-family: Arial, sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    display: block;
  }

  .three-column-form {
    position: absolute;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 106px;
    left: 48%;
  }

  /*.form-label,*/
  /*.form-input {*/
  /*  flex: 0 0 30%; !* 每个元素占据 30% 的宽度，考虑边距 *!*/
  /* !* 调整元素之间的间距 *!*/
  /*  margin-top: 3px;*/
  /*}*/

  .form-input {
    width: 48%;
    background-color: transparent;
    padding-right: 8px;
    background-color: white;
    border: 2px solid #ccc;
  }

  .form-input7 {
    margin-left: 105px;
    margin-top: 0px;
    width: 48%;
    background-color: transparent;
    /*padding-right: 8px;*/
    /*background-color: white;*/
    /*border: 2px solid #ccc;*/
    border:none;
    text-align: center;
    outline: none; /* 取消默认的焦点边框 */
  }

    .form-input8 {
      margin-top: 29px;
      margin-left: 84px;
    width: 48%;
    background-color: transparent;
    padding-right: 8px;
    /*background-color: white;*/
    /*border: 2px solid #ccc;*/
      text-align: center;
      border:none;
      outline: none; /* 取消默认的焦点边框 */
  }

    .form-input6 {
    width:39%;
    background-color: transparent;
    margin-bottom: 14px;
    background-color: white;
    border: 2px solid #ccc;
  }

  .form-input2 {
    width: 40%;
    background-color: transparent;
    margin-top: 74px;
    background-color: white;
    border: 2px solid #ccc;
  }
  .form-input22 {
    width: 40%;
    background-color: transparent;
    margin-top: 17px;
    background-color: white;
    border: 2px solid #ccc;
  }
  .form-label {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
  }
    .form-label99 {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .form-button {
    flex: 0 0 100%;
    margin-top: 10px;
  }

  .lower-content .left-box {
    background-color: #f0f0f0;
    /* Replace with your desired background color */
    margin-right: 18px;
    margin-left: 18px;
    margin-bottom: 5px;
    border: 1px solid #000;
  }

  .lower-content .right-box {
    background-color: #f0f0f0;
    /* Replace with your desired background color */
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
  }

  .lower-content {
    /* Add styles for the lower part if needed */
    display: flex;
    flex: 1;
  }

  .form2-button {
    margin-top: 12px;
    width: 110px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    /*font-weight: bold;*/
    position: absolute;
    left: 24%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    border: none
  }

  .form-button {
    margin-top: 12px;
    width: 110px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    /*font-weight: bold;*/
    position: absolute;
    left: 53%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    border: none;
  }



  .plot-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .curve {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .lower-button {
    margin-top: 13px;
    width: 110px;
    height: 30px;
    background-color: #ccc;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    /* position: absolute; */
    left: 22%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    margin-right: 13px;
  }

    .lower-button1 {
    margin-top: 31px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    /* position: absolute; */
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    margin-right: 13px;
    margin-left: 40px;
  }

  .lower-button2 {
    margin-top: 13px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    /* position: absolute; */

    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    margin-right: 13px;
    margin-left:30px
  }

    .lower-button4 {
    margin-top: 3px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    /* position: absolute; */
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    margin-left:240px;
    /*left:49%;*/
  }

    .lower-button3 {
    margin-top: 28px;
    width: 110px;
    height: 30px;
    background-color: #ccc;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    /* position: absolute; */
    left: 27%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
    margin-right: 13px;
    margin-left:110px
  }

  .three-column-form1{
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    position: absolute;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 120px;
    margin-left: 65px;
    left: 50%;
    
  }

  .form-item{
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    height: 100%;
    width: 33%;
  }

  .form-input1 {
    width: 80%;
    background-color: transparent;
    margin: 10px;
    margin-right: 8px;
    padding-right: 8px;
    background-color: white;
    border: 2px solid #ccc;
  }

    .form-input99 {
    width: 55%;
    background-color: transparent;
    margin: 10px;
    margin-right: 8px;
    padding-right: 8px;
    background-color: white;
    border: 2px solid #ccc;
      display: inline-block;
  }

  .predict-button{
    margin-top: 400px;
    width: 90px;
    height: 30px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    border: none;
    /*font-weight: bold;*/
    position: absolute;
    left: 40%;
    border-radius: 10px;
    /* 圆角半径，根据需要调整 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    /* 添加投影效果 */
    font-family: Arial, sans-serif;
    /* 使用Arial黑体字体 */
    font-size: 16px;
  }
</style>