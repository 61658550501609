var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"word-like-container"},[_c('h1',{staticClass:"title"},[_vm._v("帮助文档 / Document")]),_c('router-link',{staticClass:"home-link",attrs:{"to":"/"}},[_vm._v("返回 / back")]),_c('div',{staticClass:"login-register-links"},[_c('router-link',{staticClass:"login-link",attrs:{"to":"/help"}},[_vm._v("汉语文档")]),_c('router-link',{staticClass:"register-link",attrs:{"to":"/document"}},[_vm._v("English Document")])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 该图为主页部分，点击【登录/注册】可以进入【登录/注册】页面。需要先注册和登录才可以使用功能。 点击轮转图的【衍散射】【吸收谱】【图像】可进入相应的页面。其中部分功能仍在完善中。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/home.png"),"alt":"Image 1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 点击【登录/login】进入登录页面填入相应用户名和密码，如果没有注册过，点击注册。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/2.png"),"alt":"Image 2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 点击【注册/register】进行注册，如果已注册，则点击【登录】。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/3.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 点击【用户】可以修改个人信息。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/4.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 衍散射由四个部分组成，从左到右，从上到下，依次是高分辨率图像重构、初始化参数（将图像转为曲线的参数配置）、 图像积分曲线（从高分辨率图像中转换得到）和基于积分曲线预测分子轮廓参数。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/11.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 高分辨率图像重构先点击【上传图像】，选择tif文件上传；点击【重建】，可以将上传的tif文件展示，然后将深度学习模型重构后的图片 展示在右侧框中，点击【保存png】【保存tif】可以得到图片文件，点击【保存dat】可以将图片转为积分曲线的dat文件进行保存。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/12.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 点击【加载配置】可以将图像转为积分曲线的计算参数显示，可以进行修改。 点击【保存配置】可以将参数保存。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/13.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 点击【加载dat】可以上传自己的dat显示其中。 点击【积分】则是将我们超分辨率重构图像的积分曲线显示其中。 点击【相减】可以选择两个积分图线的相减，得到相减的曲线。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/14.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"paragraph"},[_vm._v(" 点击【预测】将相减的曲线预测出分子轮廓参数。 ")]),_c('img',{staticClass:"image",attrs:{"src":require("@/assets/15.png"),"alt":"Image 3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/16.png"),"alt":"Image 3"}})])
}]

export { render, staticRenderFns }