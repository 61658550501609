<template>
  <div>
  <div class="word-like-container">
    <!-- 标题 -->
    <h1 class="title">帮助文档 / Document</h1>
    <router-link to="/" class="home-link">返回 / back</router-link>
    <!-- 登录/注册链接 -->
    <div class="login-register-links">
      <router-link to="/help" class="login-link">汉语文档</router-link>
      <router-link to="/document" class="register-link">English Document</router-link>
    </div>
    <div>
    <!-- 第一段文字 -->
    <p class="paragraph">
      The image represents a section of the homepage; clicking on 【登录/注册】
      will lead you to the 【登录/注册】 (Login/Register) page. Registration and login
      are required to access the functionalities.Clicking on the rotating images labeled 【衍散射】 (Diffraction),
      【吸收谱】 (Absorption Spectrum),
      and 【图像】 (Image) will take you to the respective pages. Some functionalities are still under development.
    </p>

    <!-- 第一张图片 -->
    <img src="@/assets/home.png" alt="Image 1" class="image">
    </div>

    <div>
    <!-- 第二段文字 -->
    <p class="paragraph">
      Click on 【登录/login】 to access the login page, where you can enter
      the corresponding username and password. If you haven't registered yet, click on "注册" (Register) to sign up.
    </p>

    <!-- 第二张图片 -->
    <img src="@/assets/2.png" alt="Image 2" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      Click on 【注册/register】 to proceed with the registration. If already registered, click on 【登录】 (Login).
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/3.png" alt="Image 3" class="image">
    </div>
    <!-- 添加一个占位元素，创建一些空间 -->

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      Clicking on 【用户】 allows you to modify personal information.
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/4.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
The diffraction consists of four parts, from left to right, and from top to bottom, respectively:

High-Resolution Image Reconstruction: Reconstruction of the image in high resolution.
Initialization Parameters: Configuration of parameters by converting the image into curves, including the initial settings for the image.
Image Integration Curve: Curve obtained by transforming the high-resolution image, used for analysis and processing.
Prediction of Molecular Contour Parameters based on Integration Curve: Utilizing the integration curve for predicting parameters related to the molecular contour.
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/5.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
To reconstruct a high-resolution image, first click on 【上传图像】 (Upload Image), choose a .tif file for upload; click on 【重建】 (Reconstruct), and the uploaded .tif file will be displayed. Subsequently, the image reconstructed by the deep learning model will be showcased in the right-hand box. Click on 【保存png】 (Save as PNG) or 【保存tif】 (Save as TIFF) to obtain image files, and click on 【保存dat】 (Save as DAT) to convert the image into a .dat file for the integration curve and save it.
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/6.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
Click on 【加载配置】(load cfg) to display the calculation parameters for converting the image into an integration curve, allowing for modifications.
Click on 【保存配置】(save cfg)  to save the parameters.
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/7.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
Click on 【加载dat】(load dat) to upload and display your own .dat file.
Click on 【积分】(Integrate) to display the integration curve of our super-resolution reconstructed image.
Click on 【相减】(Subtract) to choose two integration curves for subtraction, obtaining the subtracted curve.
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/8.png" alt="Image 3" class="image">
    </div>



    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
Click on 【预测】(predict) to predict molecular contour parameters from the subtracted curve.
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/9.png" alt="Image 3" class="image">
    </div>


    <div>
    <!-- 第三段文字 -->
    <!-- 第三张图片 -->
    <img src="@/assets/10.png" alt="Image 3" class="image">
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'WordLikeComponent',
}
</script>

<style scoped>
.word-like-container {
  max-width: 700px;
  margin: 0 auto;
  background-color: #e6f7ff;
  padding: 20px;
  overflow-y: scroll; /* 修改为 auto，只有当内容溢出时才显示滚动条 */
  height: 100vh;
  }

.title {
  color: #333;
  text-align: center;
}

.paragraph {
  margin-bottom: 10px;
}

.image {
  width: 100%;
  height: auto;
  max-width: 650px;
  max-height: 450px;
  display: block;
  margin-bottom: 20px;
  box-sizing: border-box; /* 防止padding影响元素总体尺寸 */
}

.login-register-links {
  text-align: center;
}

.home-link {
  display: inline-block;
  margin: 0 10px;
  color: red;
  text-decoration: none;
}

.login-link,
.register-link {
  display: inline-block;
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
}
</style>
