<template>
  <div class="full-container">
    <div class="navbar">
      <div class="brand">
        <span class="brand-name">IPS Brain</span>
      </div>
      <div class="menu">
        <div
          class="menu-item1"
          @mouseover="highlightMenuItem(0)"
          @mouseleave="resetMenuItem()"
        >
          <span :class="{ highlighted: highlightedIndex === 0 }">首页</span>
        </div>
        <div
          class="menu-item"
          @mouseover="highlightMenuItem(1)"
          @mouseleave="resetMenuItem()"
        >
          <span :class="{ highlighted: highlightedIndex === 1 }">衍散射</span>
        </div>
        <div
          class="menu-item"
          @mouseover="highlightMenuItem(2)"
          @mouseleave="resetMenuItem()"
        >
          <span :class="{ highlighted: highlightedIndex === 2 }">吸收谱</span>
        </div>
        <div
          class="menu-item"
          @mouseover="highlightMenuItem(3)"
          @mouseleave="resetMenuItem()"
        >
          <span :class="{ highlighted: highlightedIndex === 3 }">成像</span>
        </div>
        <div
          class="menu-item"
          @mouseover="highlightMenuItem(4)"
          @mouseleave="resetMenuItem()"
        >
          <span :class="{ highlighted: highlightedIndex === 4 }">用户中心</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="center">
        <div>
          <div>
            <el-card style="margin-bottom: 20px">
              <div
                id="multiChart"
                style="width: 400px; height: 400px; margin-left: 40px"
              ></div>
            </el-card>
          </div>
          <div>
            <el-upload
              class="upload-demo"
              action="http://127.0.0.1:8088/api/diffraction/uploadfiles/"
              :on-success="handleSuccess"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传dat文件，且不超过500kb
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <div>
            <el-card style="margin-bottom: 20px">
              <div
                id="subtractChart"
                style="width: 400px; height: 400px; margin-left: 40px"
              ></div>
            </el-card>
          </div>
          <el-popover placement="right" width="350px" trigger="click">
            <el-form label-position="right" label-width="100px">
              <el-form-item label="被减数曲线">
                <el-select v-model="valueSubtracted" placeholder="被减数曲线">
                  <el-option
                    v-for="(file, index) in chartFilenameList"
                    :key="index"
                    :value="index"
                    :label="file"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="减数曲线">
                <el-select v-model="valueSubtract" placeholder="减数曲线">
                  <el-option
                    v-for="(file, index) in chartFilenameList"
                    :key="index"
                    :value="index"
                    :label="file"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="subtract">确定</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference">subtract</el-button>
          </el-popover>
          <el-button type="primary" @click="predict">predict</el-button>
        </div>
      </div>
      <div class="rightright">
        mw:<el-input v-model="mw" placeholder="请输入内容"></el-input>
        dmax:<el-input v-model="dmax" placeholder="请输入内容"></el-input>
        Rg:<el-input v-model="Rg" placeholder="请输入内容"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
// import VChart from "v-charts";
export default {
  // components: {
  //   VChart,
  // },
  data() {
    return {
      subtractChartOptions: {},
      subtractData: "",
      valueSubtracted: "",
      valueSubtract: "",
      chartFilenameList: [],
      subtractChart: null, //substract
      multioptions: {},
      multichart: null,
      chartDataList: [],
      fileList: [],
      result: "",
      highlightedIndex: -1,
      mw: "",
      dmax: "",
      Rg: ""
    };
  },
  mounted() {
    this.initMultiChart();
    this.initsubtractChart();
  },
  watch: {
    // 监听 result 的变化
    result: {
      handler(newResult) {
        // 当 result 改变时更新图表数据
        this.updateChart(newResult);
      },
      deep: true, // 深度监听对象内部的变化
    },
  },
  methods: {
    updateSubtractChartOptions() {
      this.subtractChartOptions = {
        animation: false,
        title: {
          text: "相减后的积分曲线",
          // textAlign:'center',
          top: "bottom",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 40,
          left: 50,
          right: 40,
          bottom: 50,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          name: "I",
          minorTick: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis: {
          name: "Q",
          min: -5,
          max: 5,
          minorTick: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        dataZoom: [
          {
            show: true,
            type: "inside",
            filterMode: "none",
            xAxisIndex: [0],
            startValue: -0.25,
            endValue: 0.5,
          },
          {
            show: true,
            type: "inside",
            filterMode: "none",
            yAxisIndex: [0],
            startValue: -0.5,
            endValue: 0.8,
          },
        ],
        series: [
          {
            type: "line",
            showSymbol: false,
            clip: true,
            data: this.subtractData,
          },
        ],
      };
      console.log("subtractChartOptions", this.subtractChartOptions);
      this.subtractChart.setOption(this.subtractChartOptions, true);
    },
    initsubtractChart() {
      this.subtractChart = echarts.init(
        document.getElementById("subtractChart")
      );
      this.updateSubtractChartOptions();
    },
    subtract() {
      console.log(this.valueSubtract);
      console.log(this.valueSubtracted);
      var formData = {
        subtracted: this.chartFilenameList[this.valueSubtracted],
        subtract: this.chartFilenameList[this.valueSubtract],
      };
      console.log('formdata',formData)
      axios
        .post("http://127.0.0.1:8088/api/diffraction/subtract/", formData)
        .then((response) => {
          console.log(response)
          this.subtractData = response.data.data_dict_list;
          this.updateSubtractChartOptions()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initMultiChart() {
      this.multichart = echarts.init(document.getElementById("multiChart"));
      this.updataMultiChartOptions();
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handlePreview(file) {
      console.log("handlepreview", file);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleRemove(file, fileList) {
      // console.log("remove",file)
      let index = this.chartFilenameList.indexOf(file.name);
      this.chartDataList.splice(index, 1);
      this.chartFilenameList.splice(index, 1);
      this.updataMultiChartOptions();
      console.log("chartdata", this.chartDataList);
      console.log("chartfilename", this.chartFilenameList);
    },
    updataMultiChartOptions() {
      var series = [];
      for (var i = 0; i < this.chartDataList.length; i++) {
        var data = {
          name: this.chartFilenameList[i],
          type: "line",
          showSymbol: false,
          clip: true,
          data: this.chartDataList[i],
        };
        series.push(data);
      }
      // console.log("series",series)
      this.multioptions = {
        animation: false,
        title: {
          text: "积分曲线",
          // textAlign:'center',
          top: "bottom",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.chartFilenameList,
        },
        grid: {
          top: 40,
          left: 50,
          right: 40,
          bottom: 50,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          name: "I",
          minorTick: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis: {
          name: "Q",
          min: -5,
          max: 5,
          minorTick: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        dataZoom: [
          {
            show: true,
            type: "inside",
            filterMode: "none",
            xAxisIndex: [0],
            startValue: -0.25,
            endValue: 0.5,
          },
          {
            show: true,
            type: "inside",
            filterMode: "none",
            yAxisIndex: [0],
            startValue: -0.5,
            endValue: 1.5,
          },
        ],
        series: series,
      };
      console.log("multioptions", this.multioptions);
      this.multichart.setOption(this.multioptions, true);
    },
    handleSuccess(response, file, fileList) {
      if (file.response.message == "成功") {
        this.chartDataList.push(file.response.data_dict_list);
        this.chartFilenameList.push(file.name);
        this.updataMultiChartOptions();
        // console.log("file", file)
        // console.log("fileList", fileList)
      }
    },
    highlightMenuItem(index) {
      this.highlightedIndex = index;
    },
    resetMenuItem() {
      this.highlightedIndex = -1;
    },
    predict() {
      var formData = {
        subtracted: this.chartFilenameList[this.valueSubtracted],
        subtract: this.chartFilenameList[this.valueSubtract],
      };
      axios.post("http://127.0.0.1:8088/api/diffraction/predict/",formData)
          .then(response => {
            var result = response.data
            this.mw = result.mw + "kDa";
            this.dmax = result.dmax + "nm";
            this.Rg = result.Rg + "nm";
          })
    },
  },
};
</script>

<style scoped>
.custom-link {
  text-decoration: none;
  color: inherit;
  /* 继承父级元素的颜色 */
}

.full-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f2f2f2;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
}

/*.brand-name {*/
/*  font-family: 'Brush Script MT', cursive;*/
/*  font-size: 60px;*/
/*  -webkit-text-stroke: 2px #5bc0de; !* 设置文本描边，2px为描边宽度，#5bc0de为蓝色 *!*/
/*  -webkit-background-clip: text;*/
/*  text-fill-color: transparent; !* 设置文本颜色为透明，使得描边颜色可见 *!*/
/*  background: linear-gradient(to right, #5bc0de 0%, #007bff 100%);*/
/*}*/

.brand-name {
  font-family: "Brush Script MT", cursive;
  font-size: 60px;
  -webkit-text-fill-color: transparent;
  /* 字体颜色透明 */
  background-image: linear-gradient(to right, #007bff, #00bfff);
  /* 渐变蓝色边缘 */
  -webkit-background-clip: text;
  /* 用于让文字仍然可见 */
}

.menu {
  display: flex;
}

.menu-item {
  margin: 0 20px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "微软雅黑";
  font-size: 48px;
  color: rgb(100, 149, 237);
  padding: 8px 16px;
  /* 添加内边距以增加目标区域 */
  border: 2px solid transparent;
  /* 初始时边框透明 */
}

.menu-item .highlighted {
  color: rgb(100, 149, 237);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: text;
}

.menu-item:hover {
  transform: scale(1);
  border: 2px solid transparent;
  /* 重置边框透明度 */
  border-image: linear-gradient(to right, #4caf50, #00ff00);
  /* 渐变绿色边框 */
  border-image-slice: 1;
  /* 定义边框宽度 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  /* 添加立体的黑色投影 */
}

.menu-item1 {
  margin: 0 20px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "微软雅黑";
  font-size: 48px;
  color: #5bc0de;
  padding: 8px 16px;
  /* 添加内边距以增加目标区域 */
  border: 2px solid transparent;
  /* 初始时边框透明 */
}

.menu-item1 .highlighted {
  color: #5bc0de;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: text;
}

.menu-item1:hover {
  transform: scale(1);
  border: 2px solid transparent;
  /* 重置边框透明度 */
  border-image: linear-gradient(to right, #4caf50, #00ff00);
  /* 渐变绿色边框 */
  border-image-slice: 1;
  /* 定义边框宽度 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  /* 添加立体的黑色投影 */
}

.content-container {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-button {
  width: 560px;
  height: 240px;
  /*background: linear-gradient(to right, #007bff, #00bfff);#00FFFF, #0000FF*/
  background: linear-gradient(to right, #00ffff, #007bff);
  color: black;
  font-family: Arial, sans-serif;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 100px;
  /* 圆角属性，使主按钮的左右两侧为圆弧 */
  border: transparent;
}

.sub-buttons {
  display: flex;
  justify-content: space-between;
  width: 460px;
  margin-top: 40px;
  /* 增加上边距 */
  margin-bottom: 20px;
  /* 增加下边距 */
}

.green-button,
.white-button {
  width: 210px;
  height: 70px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  margin: 0 30px;
  /* 增加按钮之间的外边距 */
}

.green-button {
  background-color: rgba(0, 256, 0, 0.25);
  /* 浅绿色，透明度为 25% */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.65);
  border: 3px solid transparent;
}

.white-button {
  border-image: linear-gradient(to right, #4caf50, #00ff00);
  color: black;
  border: 3px solid lightskyblue;
}

.container {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 1%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.two-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-type {
  width: 50%;
}

.center {
  width: 40%;
}

.right {
  width: 40%;
}
.rightright{
  width: 20%;
}
</style>
