<template>
  <div>
  <div class="word-like-container">
    <!-- 标题 -->
    <h1 class="title">帮助文档 / Document</h1>
    <router-link to="/" class="home-link">返回 / back</router-link>
    <!-- 登录/注册链接 -->
    <div class="login-register-links">
      <router-link to="/help" class="login-link">汉语文档</router-link>
      <router-link to="/document" class="register-link">English Document</router-link>
    </div>
    <div>
    <!-- 第一段文字 -->
    <p class="paragraph">
      该图为主页部分，点击【登录/注册】可以进入【登录/注册】页面。需要先注册和登录才可以使用功能。
      点击轮转图的【衍散射】【吸收谱】【图像】可进入相应的页面。其中部分功能仍在完善中。
    </p>

    <!-- 第一张图片 -->
    <img src="@/assets/home.png" alt="Image 1" class="image">
    </div>

    <div>
    <!-- 第二段文字 -->
    <p class="paragraph">
      点击【登录/login】进入登录页面填入相应用户名和密码，如果没有注册过，点击注册。
    </p>

    <!-- 第二张图片 -->
    <img src="@/assets/2.png" alt="Image 2" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      点击【注册/register】进行注册，如果已注册，则点击【登录】。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/3.png" alt="Image 3" class="image">
    </div>
    <!-- 添加一个占位元素，创建一些空间 -->

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      点击【用户】可以修改个人信息。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/4.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      衍散射由四个部分组成，从左到右，从上到下，依次是高分辨率图像重构、初始化参数（将图像转为曲线的参数配置）、
      图像积分曲线（从高分辨率图像中转换得到）和基于积分曲线预测分子轮廓参数。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/11.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      高分辨率图像重构先点击【上传图像】，选择tif文件上传；点击【重建】，可以将上传的tif文件展示，然后将深度学习模型重构后的图片
      展示在右侧框中，点击【保存png】【保存tif】可以得到图片文件，点击【保存dat】可以将图片转为积分曲线的dat文件进行保存。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/12.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      点击【加载配置】可以将图像转为积分曲线的计算参数显示，可以进行修改。
      点击【保存配置】可以将参数保存。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/13.png" alt="Image 3" class="image">
    </div>

    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      点击【加载dat】可以上传自己的dat显示其中。
      点击【积分】则是将我们超分辨率重构图像的积分曲线显示其中。
      点击【相减】可以选择两个积分图线的相减，得到相减的曲线。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/14.png" alt="Image 3" class="image">
    </div>


    <div>
    <!-- 第三段文字 -->
    <p class="paragraph">
      点击【预测】将相减的曲线预测出分子轮廓参数。
    </p>

    <!-- 第三张图片 -->
    <img src="@/assets/15.png" alt="Image 3" class="image">
    </div>



    <div>
    <!-- 第三段文字 -->
    <!-- 第三张图片 -->

    <img src="@/assets/16.png" alt="Image 3" class="image">
    </div>
  </div>

  </div>
</template>

<script>
export default {
  name: 'WordLikeComponent',
}
</script>

<style scoped>
.word-like-container {
  max-width: 700px;
  margin: 0 auto;
  background-color: #e6f7ff;
  padding: 20px;
  overflow-y: scroll; /* 修改为 auto，只有当内容溢出时才显示滚动条 */
  height: 100vh;
  }

.title {
  color: #333;
  text-align: center;
}

.paragraph {
  margin-bottom: 10px;
}

.image {
  width: 100%;
  height: auto;
  max-width: 650px;
  max-height: 450px;
  display: block;
  margin-bottom: 20px;
  box-sizing: border-box; /* 防止padding影响元素总体尺寸 */
}

.login-register-links {
  text-align: center;
}

.home-link {
  display: inline-block;
  margin: 0 10px;
  color: red;
  text-decoration: none;
}

.login-link,
.register-link {
  display: inline-block;
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
}
</style>
