// main.js
import Vue from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import router from "@/router";
import './plugins/element.js'

// Vue.prototype.$visited = false;
// Vue.prototype.$userbiao = '黎明';
// Vue.prototype.$passwordbiao = '';

Vue.prototype.$globalData = {
  visited: localStorage.getItem('visited') === 'true',
  userbiao: localStorage.getItem('userbiao') || '',
  passwordbiao: localStorage.getItem('passwordbiao') || '',
};

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router
}).$mount('#app');
// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
//
// createApp(App).use(router).mount('#app')

router.beforeEach((to, from, next) => {
  // Check if the route requires validation and if the 'visited' flag is true
  const requiresValidation = ['/sp4', '/sp84', '/he62','/he63']; // Update with your route paths
  if (requiresValidation.includes(to.path) && !Vue.prototype.$globalData.visited) {
    // Show alert or custom modal to prompt the user to login or register
    const confirmed = window.confirm('请先登录或注册/please login or register');

    if (confirmed) {
      // Redirect to login page
      next('/login'); // Update with your login route
    } else {
      // Cancel the navigation
      next('/');
    }
  } else {
    // Continue with the navigation
    next();
  }
});
