<template>
  <div class="background-container">
    <img class="background-image" src="../assets/home2.png" alt="Background Image">
  <div class="registration-form">
    <form @submit.prevent="register" class="form-container">
      <label for="username">用户名/Username:</label>
      <input type="text" v-model="username" required />

      <label for="password">密码/Password:</label>
      <input type="password" v-model="password" required />

      <label for="gender">性别/Gender:</label>
      <input type="text" v-model="gender" />

      <label for="age">年龄/Age:</label>
      <input type="number" v-model="age" />

      <label for="occupation">职业/Occupation:</label>
      <input type="text" v-model="occupation" />

      <label for="city">城市/City:</label>
      <input type="text" v-model="city" />

      <button type="submit" class="register-button">注册Register</button>
    </form>

    <p v-if="error" class="error-message">{{ error }}</p>
    <p class="registered-link">
      已注册？<router-link to="/login">请登录</router-link>
    </p>
    <p class="registered-link2">
      <router-link to="/">回到主页面/homepage</router-link>
    </p>
  </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      gender: '',
      age: null,
      occupation: '',
      city: '',

      visited: false,
      error: null,
    };
  },
  methods: {
    register() {
      // Reset error message
      this.error = null;

      // Make a POST request to your Django registration API
      axios.post('http://8.130.51.49:8088/register/', {
        username: this.username,
        password: this.password,
        gender: this.gender,
        age: this.age,
        occupation: this.occupation,
        city: this.city,
      })
      .then(response => {
        if (response.data.success) {
          // this.visited = true;  // Set the visited variable to true on successful registration
          // this.$userbiao=this.username;
          // this.$passwordbiao=this.password;
          this.$globalData.visited = true;
          this.$globalData.userbiao = this.username;
          this.$globalData.passwordbiao = this.password;

          // Save the values to localStorage
          localStorage.setItem('visited', 'true');
          localStorage.setItem('userbiao', this.username);
          localStorage.setItem('passwordbiao', this.password);
          this.showSuccessDialog(); // Call the function to show the success dialog
        } else {
          this.error = response.data.message || 'Registration failed.';
        }
      })
      .catch(error => {
        console.error('Error registering user:', error);
        this.error = '登陆失败，请重试/Registration failed. Please try again later.';
      });
    },
    showSuccessDialog() {
      // Display a simple alert as a success dialog
      window.alert('登录成功/Registration successful!');

      // After the alert is closed, navigate to another page
      this.$router.push('/');
      // You can replace '/other-page' with the path of the page you want to navigate to
    }
  }
}
</script>

<style scoped>
.background-container {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
}

/* 背景图片样式 */
.background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: fill;
}

.registration-form {
  top:10%;
  max-width: 400px;
  margin: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.register-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register-button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.registered-link {
  margin-top: 10px;
  text-align: center;
}

.registered-link a {
  color: #008CBA;
  text-decoration: none;
  font-weight: bold;
}

.registered-link a:hover {
  text-decoration: underline;
}

.registered-link2 {
  margin-top: 10px;
  text-align: center;
}

.registered-link2 a {
  color: lightseagreen;
  text-decoration: none;
  font-weight: bold;
}

.registered-link2 a:hover {
  text-decoration: underline;
}
</style>