import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入页面组件

// import student from './components/student.vue';


import DatUpload from '@/components/DatUpload'
import sp10 from "@/components/sp10";
import he63 from "@/components/he63"
import he62 from "@/components/he62"
import user6 from "@/components/user6"
import login8 from "@/components/login8"
import register8 from "@/components/register8"
import help from "@/components/help"
import help6 from "@/components/help6"
Vue.use(VueRouter);

const routes = [
    {path: '/', component: sp10 },
    {path:'/diffraction/upload',component:DatUpload},
    {path:'/he62',component: he62},
    {path:'/he63',component: he63},
    {path:'/user',component: user6},
    {path:'/register',component: register8},
    {path:'/login',component: login8},
    {path:'/help',component: help},
    {path:'/document',component: help6}
];

const router = new VueRouter({
  routes,
});

export default router;